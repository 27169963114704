import "./Footer.css";
import logo from "../../images/logo.svg";
import triick_approved_developer from "../../images/triick_approved_developer.svg";
function Footer(props) {
  return (
    <footer className="footer" data-scroll-section>
      <div className="container">
        <div className="footer_content_wrap">
          <div className="footer_logo_wrap">
            <img className="logo-img" src={logo} alt="TRICK" />
            <img
              className="approve_img"
              src={triick_approved_developer}
              alt="triick approved developer"
            />
          </div>
          <div className="footer_content_main">
            <div className="footer_content_colunm">
              <h4 className="heading_3">OFFICE</h4>
              <p className="footer_subtitle">
                TRIICK Limited
                <span className="footer_text">Harbourside</span>
                <span className="footer_text">House 2 Downley Rd</span>
                <span className="footer_text">Havant, PO9 2NJ</span>
                <span className="footer_text">United Kingdom</span>
              </p>
              <a href="tel:+447775430555" className="footer_text footer_link">
                +44 7775 430555
              </a>
            </div>
            <div className="footer_content_colunm">
              <h4 className="heading_3">EMAIL</h4>
              <div className="footer_email_wrap">
                <p className="footer_subtitle">
                  Work with Triick
                  <a
                    href="mailto:letstalk@triick.co.uk"
                    className="footer_text footer_link"
                  >
                    letstalk@triick.co.uk
                  </a>
                </p>
                <p className="footer_subtitle">
                  Join Triick
                  <a
                    href="mailto:careers@triick.co.uk"
                    className="footer_text footer_link"
                  >
                    careers@triick.co.uk
                  </a>
                </p>
                <p className="footer_subtitle">
                  General Enquiries
                  <a
                    href="mailto:letstalk@triick.co.uk"
                    className="footer_text footer_link"
                  >
                    letstalk@triick.co.uk
                  </a>
                </p>
              </div>
            </div>
            <div className="footer_content_colunm">
              <h4 className="heading_3">SOCIAL</h4>
              <a
                href="https://www.linkedin.com/company/triick"
                target="_blank" rel="noreferrer"
                className="footer_text footer_link"
              >
                LinkedIn
              </a>
              <a
                href="https://www.instagram.com/triickdev"
                target="_blank" rel="noreferrer"
                className="footer_text footer_link"
              >
                Instagram
              </a>
              <a
                href="https://www.facebook.com/triickdev"
                target="_blank" rel="noreferrer"
                className="footer_text footer_link"
              >
                Facebook
              </a>
            </div>
            {/* <div className="footer_content_colunm">
              <a
                href="https://www.designrush.com/agency/profile/tri-ck"
                target="_blank"
                className="footer_img_link "
              >
                <img
                  src={view_profile}
                  alt="View Profile"
                  className="footer_img"
                />
              </a>
              <a
                href="https://www.designrush.com/agency/profile/tri-ck"
                target="_blank"
                className=" footer_img_link"
              >
                <img
                  src={topAppCompany}
                  alt="Top App Developing Company"
                  className="footer_img"
                />
              </a>
            </div> */}
          </div>
          <div className="footer_bottom">
            <p className="footer_bottom_text">
              &copy; TRIICK. All rights reserved
            </p>
            <a href="./privacy-policy" className="footer_bottom_text">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
